import React from 'react'

const Footer = () => {
  return (
    <div id="footer">
      <img
        src="https://static.prodeko.org/static/images/logos/prodeko-logo-text-blue.png"
        alt="Prodeko"
      />
    </div>
  )
}
Footer.propTypes = {}

export default Footer
